@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes decrease {
  0% {
    transform: scale(1.075);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes appearance {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}