.page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;

  width: 22.75rem;
  max-width: 100%;

  margin: 0 auto;
  padding: 3.75rem 0 0;

  @media screen and (max-width: 767px) {
    padding: 2rem 0 0;
  }
}