.counter {
  display: block;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  font-size: 1rem;
  line-height: 1;

  text-align: left;

  strong {
    display: flex;
    align-items: center;

    margin: 0 0.25rem 0 -0.25rem;

    font-size: 3.25rem;
    font-weight: 700;

    line-height: 1;
  }

  em {
    display: block;
    margin-left: 0.25rem;

    font-size: 1.25rem;
    font-weight: 400;
    font-style: normal;
  }
}