.versus {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;

  width: 3.5rem;
  height: 3.5rem;

  font-size: 1.125rem;
  line-height: 1;

  color: var(--color-dark);
  background-color: var(--color-light);

  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);

  transform: translate(-50%, -50%);
  user-select: none;
}