.warning {
  display: block;

  margin: 0;
  font-size: 1rem;
  text-align: center;

  cursor: default;

  strong {
    display: block;
    margin: 0 0 1rem;

    font-weight: 400;
    font-size: 1.375rem;
  }

  span {
    display: block;
  }

  button {
    margin: 0;
    padding: 0;

    border: 0;

    border-bottom: solid 1px;
    transition: border 0.25s;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  &.on-discuss {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: 0.5rem 0 0;

    font-size: 0.875rem;
    font-weight: 400;
  }

  &.on-feedback {
    display: block;

    width: 100%;
    margin: 0;
    text-align: left;

    font-size: 0.875rem;
    font-weight: 400;
  }

  &.on-fullscreen {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 40rem;
    max-width: 100%;
    padding: 0 1rem 2.75rem;
    transform: translate(-50%, -50%);

    em {
      font-style: normal;
    }
  }
}