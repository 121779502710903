.questions {
  display: grid;
  grid-template-rows: 1fr 1fr;

  position: relative;

  width: 100%;
  height: calc(var(--app-height) - 13.5rem);
  min-height: 30rem;
  max-height: 40rem;
  margin: 0 auto;
  overflow: hidden;

  font-size: 1.25rem;
  font-weight: 400;

  border-radius: 1.5rem;

  will-change: transform;

  @media screen and (max-width: 767px) {
    height: calc(var(--app-height) - 10.75rem);
    font-size: 1.125rem;
  }

  @media screen and (max-width: 359px) {
    height: calc(var(--app-height) - 10.25rem);
  }
}