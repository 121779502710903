.discuss-item {
  display: grid;
  grid-template-areas: "avatar name" "avatar message";
  grid-template-columns: 4rem 1fr;

  padding: 1rem 0;
  border-top: solid 1px rgba(0, 0, 0, 0.25);

  cursor: default;

  img {
    display: block;
    grid-area: avatar;

    width: 3rem;
    height: 3rem;
    opacity: 0;

    background-image: var(--color-field-dark);
    border-radius: 0.25rem;
  }

  span {
    display: block;
    grid-area: name;

    margin: 0 0 0.25rem;
    line-height: 1;
    font-size: 0.875rem;
  }

  p {
    display: block;
    grid-area: message;

    margin: 0;
    line-height: 1.375;
    font-weight: 400;
    word-break: break-word;
  }

  &:first-child {
    border-top: 0;
  }

  &:nth-child(odd) span {
    color: var(--color-first);
  }

  &:nth-child(even) span {
    color: var(--color-last);
  }

  &.is-loaded img {
    animation: fade-in 500ms forwards;
  }
}