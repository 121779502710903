.header {
  display: flex;
  align-items: flex-start;

  width: auto;
  max-width: 100%;
  margin: 0 auto 1.25rem;

  img {
    display: block;

    width: auto;
    max-width: 100%;
    height: 2.75rem;

    @media screen and (max-width: 359px) {
      height: 2rem;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  @media screen and (max-width: 359px) {
    margin: 0 auto 0.75rem;
  }
}