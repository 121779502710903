.toggler {
  display: block;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 10;

  height: 1.25rem;
  width: 1.375rem;

  outline-offset: 0.375rem;

  cursor: pointer;

  span {
    display: block;
    position: absolute;
    left: 0;

    height: 2px;
    width: 100%;

    background: var(--color-light);
    border-radius: 2px;

    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: calc(100% - 2px);
  }

  span:nth-child(3) {
    top: calc(50% - 1px);
  }

  &.is-opened {
    span:nth-child(1) {
      top: calc(50% - 1px);
      transform: rotate(135deg);
    }

    span:nth-child(2) {
      top: calc(50% - 1px);
      transform: rotate(-135deg);
    }

    span:nth-child(3) {
      opacity: 0;
    }
  }

  @media screen and (max-width: 767px) {
    top: 2.5rem;
    right: 2rem;
  }

  @media screen and (max-width: 359px) {
    top: 2.125rem;
    right: 2rem;
  }
}