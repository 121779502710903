.tools {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 12rem;
  max-width: 100%;
  height: 4.75rem;
  margin: 0 auto;
  padding: 1.5rem 0 0;

  button {
    display: block;
    position: relative;

    width: 2rem;
    height: 2rem;
    padding: 0.25rem;

    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    background-position: center;

    border: 0;
    transition: filter 0.375s;

    span {
      display: block;

      position: absolute;
      right: 0.125rem;
      top: 0.125rem;

      width: 0.7125rem;
      height: 0.7125rem;

      background: var(--color-last);
      border: solid 0.125rem var(--color-field-dark);

      border-radius: 50%;

      transform: scale(0);
      animation: appearance 500ms forwards;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      transform: translateY(-2px);
    }

    &:last-child {
      transform: rotate(180deg);
    }

    &:nth-child(2) {
      padding: 0.425rem;
    }

    @media (hover: hover) {
      &:hover {
        filter: brightness(70%);
      }
    }
  }

  &.is-liked button:last-child {
    cursor: not-allowed;
    filter: brightness(70%);
  }

  &.is-disliked button:first-child {
    cursor: not-allowed;
    filter: brightness(70%);
  }

  @media screen and (max-width: 767px) {
    padding: 1.25rem 0 0;
  }
}