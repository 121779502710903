.questions-item {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 100%;
  padding: 2.5rem 1.25rem;

  overflow: hidden;

  user-select: none;
  cursor: pointer;

  transition: filter 0.375s;

  p {
    display: block;

    max-height: 100%;
    margin: 0;

    text-align: center;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &::after {
    content: '';

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 5rem;
  }

  &:first-child {
    background-color: var(--color-first);
    border-radius: 1.5rem 1.5rem 0 0;

    &::after {
      background-image: linear-gradient(to top, var(--color-first) 10%, rgba(255, 255, 255, 0));
    }
  }

  &:last-child {
    background-color: var(--color-last);
    border-radius: 0 0 1.5rem 1.5rem;

    &::after {
      background-image: linear-gradient(to top, var(--color-last) 1rem, rgba(255, 255, 255, 0));
    }
  }

  &.is-recent p {
    animation: fade-in 750ms forwards;
  }

  &.is-recent:active p {
    transform: translateY(1px);
  }

  &:not(.is-recent) {
    padding: 5.25rem 1rem;
  }

  &:not(.is-recent):not(.is-picked) {
    color: rgba(255, 255, 255, 0.5);
  }

  @media (hover: hover) {
    &.is-recent:hover {
      filter: brightness(120%);
    }
  }
}