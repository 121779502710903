.content {
  display: block;
  margin: 2rem 0;

  font-size: 1rem;

  p {
    display: block;

    margin: 0 0 1rem;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    border-bottom: solid 1px;
    text-decoration: none;

    transition: border 0.125s;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  strong {
    font-weight: 700;
  }
}