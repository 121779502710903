.particles-bg-canvas-self {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-image: linear-gradient(to bottom, var(--color-field-light), var(--color-field-dark));
}