.discuss-form {
  display: block;
  position: relative;

  width: 100%;
  margin: 1.5rem 0 0;
  padding-bottom: 2rem;

  fieldset {
    display: block;
    position: relative;

    width: 100%;
    margin: 0;
    padding: 0;

    border: 0;
  }

  textarea {
    display: block;

    width: 100%;
    min-height: 2rem;
    padding: 0.625rem 2.5rem 0.625rem 0.5rem;

    font-size: 1rem;
    font-weight: 400;
    resize: none;

    background-color: transparent;
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;

    &:focus {
      outline: none;
      border-color: var(--color-last);
    }
  }

  button {
    display: block;

    position: absolute;
    top: 50%;
    right: 0.5rem;

    width: 1.25rem;
    height: 1.25rem;

    border: 0;
    transition: filter 0.375s;
    transform: translateY(-50%);

    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }

    @media (hover: hover) {
      &:hover {
        filter: brightness(80%);
      }
    }
  }

  @media screen and (max-width: 479px) {
    margin: 1rem 0;
  }
}