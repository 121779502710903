.loader {
  display: block;

  position: absolute;
  top: calc(50% - 0.5rem);
  left: calc(50% - 1.5rem);

  width: 3rem;
  height: 3rem;

  border: 0.25rem solid rgba(255, 255, 255, 0.75);
  border-bottom-color: transparent;
  border-radius: 50%;

  animation: rotation 1s linear infinite;

  &.on-discuss {
    top: calc(50% - 0.75rem);
    left: auto;
    right: 0.75rem;

    width: 1.5rem;
    height: 1.5rem;

    border-width: 0.125rem;
  }

  &.on-feedback {
    position: relative;
    top: auto;
    left: auto;

    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;

    border-width: 0.125rem;
  }
}