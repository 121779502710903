html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

html {
  color: var(--color-light);
  background-color: var(--color-field-dark);
}

body {
  max-width: 100%;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  font: 400 16px/1.5 'Ubuntu', sans-serif;

  &.is-out {
    pointer-events: none;
    filter: blur(2px);;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
}

button {
  margin: 0;
  padding: 0;

  font-family: inherit;
  font-size: inherit;

  color: inherit;
  background: transparent;
  border: 0;

  cursor: pointer;
}

input {
  color: var(--color-light);
  font-family: inherit;
}

textarea {
  color: var(--color-light);
  font-family: inherit;
  line-height: 1.25;
}

#root {
  display: block;
  position: relative;

  height: 100%;
  padding: 0 2rem;

  &::before {
    content: '';

    visibility: hidden;
    font-weight: 700;
  }
}

::placeholder {
  color: rgba(255, 255, 255, 0.375);
}