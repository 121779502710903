@font-face {
  font-family: 'Ubuntu';
  src:
    url('../fonts/ubuntu-regular.woff2') format('woff2'),
    url('../fonts/ubuntu-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ubuntu';
  src:
    url('../fonts/ubuntu-bold.woff2') format('woff2'),
    url('../fonts/ubuntu-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
