.logout {
  display: block;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 10;

  height: 2rem;
  width: 2rem;

  outline-offset: 0.375rem;

  cursor: pointer;

  svg {
    width: 1.5rem;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    top: 2.5rem;
    right: 2rem;
  }

  @media screen and (max-width: 359px) {
    top: 2.125rem;
    right: 2rem;
  }
}
