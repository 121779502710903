.moderation-item {
  display: flex;
  flex-flow: row wrap;

  width: 100%;
  margin-bottom: 2.5rem;
  overflow: hidden;

  font-weight: 400;

  p {
    margin: 0;
  }

  hr {
    height: 1px;
    background-color: var(--color-border);
    border: 0;
  }

  ul {
    display: block;

    width: 100%;
    margin: 0;
    padding: 0 0.75rem;

    list-style: none;

    background-color: var(--color-field-dark);
    border: 0;
    border-radius: 0.25rem;

    li {
      margin: 0;
      padding: 0.875rem 0;
    }

    li:first-child {
      border-bottom: solid 1px var(--color-border);
    }
  }

  fieldset {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem 1rem;

    width: 100%;
    margin: 0.75rem 0 0;
    padding: 0;

    border: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    flex: 1;

    padding: 0.6875rem 1rem;
    border-radius: 0.25rem;

    transition: filter 0.375s;

    &:first-child {
      background-color: var(--color-approve);
    }

    &:last-child {
      background-color: var(--color-decline);
    }

    svg {
      display: block;

      width: 1.25rem;
      height: auto;

      transform: translateX(-0.375rem);
    }

    span {
      margin-right: 0.375rem;
    }

    @media (hover: hover) {
      &:hover {
        filter: brightness(80%);
      }
    }
  }

  &.is-voted {
    animation: fade-out 500ms forwards;
  }
}